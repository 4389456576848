import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import { SITE_TITLE, THEME } from "../constant"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tag from "../components/tag"
import PostDate from "../components/post-date"
import styled, { ThemeProvider } from "styled-components"

const Heading1 = styled.h1`
  color: ${props => props.theme.grey600};
`

const Heading2 = styled.h2`
  color: ${props => props.theme.grey50};
  background-color: ${props => props.theme.primary300};
  padding-left: 1rem;
  font-size: 1.8rem;
  line-height: 2.6;
`

const Heading3 = styled.h3`
  color: ${props => props.theme.grey500};
  background-color: ${props => props.theme.grey50};
  border-left: solid 10px ${props => props.theme.primary300};
  padding-left: 1rem;
  font-size: 1.6rem;
  line-height: 2.2;
`

const Heading4 = styled.h4`
  color: ${props => props.theme.grey500};
  border-bottom: solid 5px ${props => props.theme.primary50};
  padding-left: 1rem;
  font-size: 1.4rem;
  line-height: 1.8;
`

const Heading5 = styled.h5`
  color: ${props => props.theme.grey400};
  border: solid 1px ${props => props.theme.primary50};
  padding: 6px 0 6px 1rem;
  font-size: 1.2rem;
  line-height: 1.4;
`

const shortcodes = {
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5
}

export default function PageTemplate({ data: { mdx } }) {
  return (
    <ThemeProvider theme={THEME}>
      <Layout>
        <SEO title={`${mdx.frontmatter.title} | ${SITE_TITLE}`} description={mdx.excerpt} image={mdx.frontmatter.key_image.publicURL} article={true}></SEO>
        <div>
          <div className="title pb-3">
            <PostDate size="sm">{mdx.frontmatter.date}</PostDate>
            <Heading1>{mdx.frontmatter.title}</Heading1>
            <ul className="d-flex pl-0 mt-2 mb-1">
              {mdx.frontmatter.tags.map((tag) => (
                <li key={tag} className="mr-3"><Tag>{tag}</Tag></li>
              ))}
            </ul>
          </div>
          <Img fluid={mdx.frontmatter.key_image.childImageSharp.fluid} />
          <div className="mt-5">
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </Layout>
    </ThemeProvider>
  )
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        tags
        key_image {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
  }
`
